.add-to-cart-blocked {
  .material-icons-text {
    display: inline-flex;

    .material-icons {
      padding-top: 1px;
    }

    div {
      padding-top: 0;
      font-size: 12px;
    }
  }
}
