.dealer-preference-row {
  td > div {
    display: flex;

    .material-icons {
      margin-right: .25em;
    }
  }

  .dealer-preference-actions {
    text-align: right;

    .btn + .btn {
      margin-left: .5em;
    }
  }

  .dealer-preference-status {
    .badge {
      display: inline-flex;
      margin-top: 3px;

      .material-icons {
        font-size: 12px;
      }

      span + span {
        margin-left: 0.1em;
        margin-top: 1px;
      }
    }
  }
}
