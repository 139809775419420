.ticket-barcode > img {
  width: 100% !important;
}

#printArea {
  .ticket-barcode > img {
    width: 300px !important;
  }
}

.ticket-barcode-redeemed {
  position: absolute;
  color: red;
  font-weight: bold;
  background-color: white;
  top: 40%;
  width: 100%;
  left: 0px;
  padding: 5px;
}

.ticket-impersonated {
  background-image: repeating-linear-gradient(
    135deg,
    #00000030,
    #00000030 20px,
    #ffb10130 20px,
    #ffb10130 40px
  );
  background-repeat: repeat-x;
  color: black;
}
