.connection-toast {
  display: flex;
  position: absolute;

  align-items: center;

  margin: 1em;
  padding: 0.5em 1.25em 0.5em 1em;
  border-radius: 10px;

  // hide toast off-screen initially
  transition: 1s;
  top: -100px;

  left: 50%;
  transform: translate(-50%, 0);
  
  // Always on top
  z-index: 9999;

  &.connection-offline {
    background-color: rgb(255, 231, 231);
    box-shadow: 0 0 10px rgba(179, 36, 36, 0.5);
    border: 1px solid rgb(184, 41, 41);
  }

  &.connection-online {
    background-color: rgb(231, 255, 231);
    box-shadow: 0 0 10px rgba(43, 179, 36, 0.5);
    border: 1px solid rgb(41, 184, 60);
  }

  .connection-icon {
    margin-right: 0.75em;
  }

  .material-icons {
    margin-top: 3px;
  }

  .connection-text {
    font-size: 0.8em;

    .connection-title {
      font-size: 1.1em;
      font-weight: 600;
    }
  }

  &.connection-toast-show {
    transition: 1s;
    top: 0;
  }
}