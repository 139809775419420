.react-error {
  ul {
    margin-top: 10px;
    margin-left: 45px;
  }

  ul > li {
    margin-bottom: 7px;

    .material-icons {
      position: absolute;
      margin-left: -30px;
      margin-top: -1px;
    }
  }
}
