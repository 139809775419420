.multi-select {
  display: flex;
  align-items: center;

  .multi-select-left, .multi-select-right {
    flex: 1;
  }

  .multi-select-buttons {
    margin: 1rem;
    flex-direction: column;
    display: flex;

    .btn:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}