.search-options {
  width: fit-content;
  border: 1px solid #ced4da;
  margin-top: -1px;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  padding: 8px 10px 0;
  font-size: 0.9em;

  .form-check-input {
    position: initial;
    margin: 0;
    margin-right: 0.5em;
  }
}

.search-empty-state {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}