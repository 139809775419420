.editable-text-boundry {
  display: inline-flex;
  align-items: center;
}

.editable-text {
  display: inline-block;
  padding: 2px 6px 2px 4px;
  border: 1px dotted #253858;
  background-color: inherit;
}