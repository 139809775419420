.cashier-header {
  display: flex;
  flex-direction: row;
  width: 100%;

  font-size: 0.8em;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  align-items: center;

  margin-bottom: 1.5em;

  .material-icons {
    display: inline-flex;
    vertical-align: middle;
    margin-top: -2px;
  }

  .cashier-header-info {
    flex: 1;

    .cashier-header-title {
      font-size: 1.4em !important;
      font-weight: 600;
      color: #333;
    }

    .cashier-printer-info {
      cursor: pointer;
      width: fit-content;

      .material-icons {
        cursor: pointer;
        font-size: 1.5em;
        margin-right: 4px;
      }
    }
  }

  .cashier-info {
    text-align: right;

    .cashier-info-username {
      font-size: 1.1em;
      font-weight: 600;
    }

    .cashier-info-date {
      min-width: 115px;
      font-size: 0.9em;
      color: #444;
    }
  }

  .cashier-avatar {
    margin-left: 15px;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid #ccc;
    }
  }

  .cashier-menu {
    display: inline-flex;
    margin-right: 10px;
  }
}

.print-settings-modal {
  h4 {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.5em;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.3em;

    div:not(.dropdown) {
      flex: 1;
    }

    .btn {
      font-size: 0.75em;
    }
  }

  div + h4 {
    margin-top: 1em;
  }

  .print-settings-printer {
    display: flex;
    font-size: 0.9em;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.3em;

    .btn {
      font-size: 0.95em;
    }

    .btn + .btn {
      margin-left: 0.5em;
    }

    .print-settings-printer-name {
      flex: 1;
    }

    + .print-settings-printer {
      margin-top: 0.5em;
    }

    .dropdown {
      display: inline;
    }
  }

  .print-settings-emptystate {
    display: flex;
    min-height: 50px;
    font-size: 0.9em;
    color: #666;
    align-items: center;
    justify-content: center;
  }
}