.cashier-current-status .cashier-status-header {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 10px;

  .material-icons {
    margin-right: 8px;
  }

  p {
    font-size: 0.9em;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.cashier-status-message {
  margin-bottom: 5px;

  ul {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.cashier-profile-back {
  display: flex;
  flex-direction: column;

  .btn {
    padding: 5px 15px;
  }

  small {
    margin-top: 1px;
    text-align: right;
  }
}

.cashier-badge-helptext {
  display: flex;
  align-items: center;

  font-size: 0.85em;

  .material-icons {
    margin-right: 5px;
  }
}

.cashier-badge-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100px;
  color: #666;

  h4 {
    font-size: 1.1em;
  }

  p {
    margin-bottom: 0;
    font-size: 0.95em;
  }
}

.cashier-payment-notenabled, .cashier-volunteer-notenabled, .cashier-idscan-error {
  align-items: center;
  display: flex;

  .material-icons {
    margin-right: 10px;
  }
}

.cashier-action-buttons {
  .hotkey-button {
    margin-bottom: 10px;
  }
}

.cashier-profile-header-userinfo {
  display: flex;
  font-size: 1em;

  .cashier-profile-header-userinfo-section {
    margin-right: 8px;

    div:last-child {
      font-size: 0.6em;
    }
  }
}