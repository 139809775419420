.file-upload {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .file-upload-droparea {
    border: 2px dashed;
    padding: 1.5em 1em;
    margin: 0 12.5%;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;

    transition: 250ms;

    > div:not(.file-upload-icon) {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    > p {
      margin-bottom: 0 !important;
      font-size: 12px;
      color: rgb(98, 98, 98);
    }

    &:hover:not(.disabled, .drag-active) {
      border-color: #0670ad;
      background-color: #E8F6FE;
    }

    &.drag-active:hover {
      border-color: #0670ad;
      background-color: #effee8;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
  
  .file-upload-input {
    display: none;
  }
}

.file-upload-item {
  margin: .5rem;
  border: 1px solid #ababab;
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  display: flex;
  cursor: default;

  .file-upload-item-name {
    flex-grow: 1;
  }

  .dont-shrink {
    flex-shrink: 0;
  }

  .material-icons {
    // REMOVE AFTER UPGRADING COMMON_FE
    display: inline-flex;
    vertical-align: middle;
    margin-top: -2px;

    &.delete {
      cursor: pointer;
      margin-left: 10px;

      &:hover {
        color: #dc3545 !important;
      }
    }

    &.cloud {
      margin-right: 5px;
    }
  }
}