.element-header {
  display: flex;
  align-items: center;

  h4 {
    margin-bottom: 0;
  }

  .element-header-title {
    margin-top: 0.1em;
  }

  .element-header-child {
    margin-top: 0.25em;
    font-size: 0.85em;

    p {
      margin-bottom: 0;
    }
  }

  .element-header-target {
    margin-right: 15px;
    display: flex;
  }

  .element-header-clear {
    flex: 1;
  }

  .element-header-actions {
    flex: 1;

    .dropdown-menu {
      font-size: 0.9em;
    }

    .dropdown-item {
      padding: 7px 10px !important;
    }
  }
}

@media (max-width: 575px) {
  .element-header {
    .float-right {
      float: none !important;
      display: block;
      margin-bottom: 0.5em;

      .cashier-profile-back small {
        display: none;
      }
    }

    .element-header-target {
      display: block;

      .element-header-actions {
        margin-top: 0.5em;
      }
    }
  }
}