$bgDark: #222;
$bgCardDark: #353535;
$highlightDark: #444;

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Armata', sans-serif !important;
  font-size: 14px;
}

html {
  position: relative;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  line-height: 1.1;
  color: inherit;

  .material-icons {
    margin-top: -3px;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h4,
h4 {
  font-size: 18px;
}

.h6,
h6 {
  font-weight: bold;
}

:not(.sidenav-active) .page-wrap {
  margin-left: 0;
}

.page-wrap {
  padding-top: 10px;
  margin-left: 300px;
  min-height: 100%;
  transition: all 0.3s;
  -webkit-overflow-scrolling: touch;
}

.page-wrap:after {
  content: '';
  display: block;
  height: 65px;
}

.pt-callout {
  margin-bottom: 15px;
}

.event-info {
  padding: 12px 10px;
  border-bottom: 1px solid #e7e7e7;

  h4,
  p {
    margin-bottom: 0;
  }
}

.material-icons.delete:hover {
  cursor: pointer;
  color: #812028 !important;
}

div.btn {
  cursor: pointer;
}

.btn .material-icons {
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 0;
  margin-top: -1px;
  cursor: pointer !important;
}

button.disabled {
  cursor: not-allowed !important;
}

.header .toggle-btn > .material-icons {
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 0;
  margin-right: 10px;
  margin-top: -1px;
}

.header.navbar {
  letter-spacing: 2px;
  text-transform: uppercase;
}

.header .navbar-brand {
  font-family: 'Krona One', sans-serif;
  font-size: 18px;
  letter-spacing: 2px;
}

.footer.navbar {
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  min-height: 55px;
  height: auto;
  line-height: normal;
  font-size: 12px;
  text-transform: uppercase;

  > div {
    display: flex;
    align-items: end;

    p {
      font-size: 10px;
      margin-left: 1rem;
      color: rgb(100, 100, 100);
    }
  }

  img {
    height: 33px;
    width: auto;
    cursor: pointer;
  }

  p {
    margin: 5px;
  }
}

.page-header {
  padding-bottom: 9px;
  margin: 0 0 20px;
  border-bottom: 1px solid #eee;
}

.text-center {
  text-align: center;
}

.margin-center {
  margin: 0 auto;
}

.info-card.panel {
  max-height: 250px;
  height: 250px;
}

.info-card .panel-body {
  min-height: 194px;
}

.card-color.info {
  background-color: #204d74 !important;
}

.card-color.danger {
  background-color: #812028 !important;
}

.card-color.success {
  background-color: #208128 !important;
}

.card-color.warning {
  background-color: #db8119 !important;
}

.card.danger {
  background-color: #fbeaec;
}

.card.success {
  background-color: #e9f6ec;
}

.card.warning {
  background-color: #fff8e6;
}

.card.info {
  background-color: #e7f5f7;
}

.cardbtn {
  margin-bottom: 10px;
}

.cardbtn:active {
  background-color: #f3f3f3;
}

.cardbtn-primary,
.cardbtn-primary:active {
  background-color: #007bff !important;
  color: white;
}

.userListItem,
.cardbtn,
.custom-control-label,
.theme-switcher div,
.nav-tabs .nav-item,
.pointer,
button .material-icons {
  cursor: pointer !important;
}

.cardbtn-disabled {
  cursor: not-allowed;

  .card-title,
  .card-text {
    opacity: 0.5;
  }

  .text-danger {
    font-weight: bold;
  }
}

.custom-control.left-align {
  text-align: left;
  margin-left: 25px;
}

.noselect,
.material-icons {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.material-avatar {
  float: left;
  margin-right: 15px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #e7e7e7;
}

.t-small {
  font-size: 12px;

  .material-icons {
    font-size: 18px;
    margin-top: 0;
  }
}

.material-icons.xsmall {
  font-size: 12px;
}

.material-icons.small {
  font-size: 18px;
}

.material-icons.medium {
  font-size: 32px;
}

.material-icons.large {
  font-size: 64px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.padding-10 {
  padding: 10px;
}

#wrapper:not(.sidenav-active) .sidenav {
  display: none;
}

.sidenav .toggle-btn {
  padding: 5px 5px 0;
  float: right;
  margin: 6px 13px;
}

.sidenav {
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  float: left;
  background-color: #f8f8f8;
  border: 1px solid #e7e7e7;
  margin-left: -100%;
  transition: all 0.3s;
  z-index: 9000;
  padding-bottom: 40px;
}

.sidenav-active {
  .sidenav {
    margin-left: 0 !important;
  }
}

.align-middle {
  vertical-align: middle;
}

.text-left {
  text-align: left;
}

.additional-options .card-body {
  padding: 0.75em 2em 1.25em;
}

.loading {
  text-align: center;
}

.sidenav .user-info {
  min-height: 150px;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  padding-top: 10px;
}

.user-info .avatar {
  float: left;
  width: 35%;
  text-align: center;
}

.avatar > img {
  min-width: 82px;
  min-height: 82px;
  border-radius: 50%;
  border: 1px solid #e7e7e7;
}

.oauth {
  font-size: 13px;

  h4 {
    margin-bottom: 1em;
  }

  .appicon {
    text-align: center;
    margin-bottom: 15px;

    img {
      max-width: 75px;
      max-height: 75px;
    }
  }

  .oauthHeader {
    display: flex;
    margin-top: 10px;
    padding-bottom: 0;

    .avatar {
      border-radius: 50%;
      margin-right: 2rem;
    }

    .oauth-access-info {
      flex: 1;
      align-self: center;
    }
  }

  .avatar > img {
    float: left;
    width: 32px;
    margin-top: 3px;
    margin-right: 15px;
  }

  .permission:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .permission div .permissionHeader {
    font-weight: bold;
  }

  .permission {
    display: flex;
    min-height: 40px;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    .material-icons {
      flex-shrink: 1;
      align-self: center;
      margin-right: 1rem;
    }

    .permission-info {
      flex: 1;
      align-self: center;
    }

    .permissionHeader {
      font-weight: bold;
      display: inline-flex;
    }

    &.permission-elevated {
      .permissionHeader .material-icons {
        margin-right: 0.5rem;
      }

      .permissionHeader,
      .material-icons {
        flex-shrink: 1;
        align-self: center;
        color: #d41111;
      }
    }
  }
}

.sidenav ul {
  padding: 0;
  list-style-type: none;
}

.sidenav li {
  border-bottom: 1px solid #e7e7e7;
}

.sidenav li .material-icons {
  float: left;
  vertical-align: middle;
  padding: 3px 3px 3px 10px;
}

.sidenav li a {
  text-decoration: none;
}

.sidenav li.sidenav-spacer {
  height: 40px;
}

.sidenav li.sidenav-header {
  padding: 10px;
  margin-top: 10px;
  font-size: 12px;
}

.sidenav li a div {
  padding: 5.5px 5px 7px 40px;
  font-size: 13px;
}

.sidenav li a:not(.active):hover div {
  background-color: #e6eff7;
  transition: all 0.3s;
}

.sidenav li a.active div,
.sidenav li a.active .material-icons {
  background-color: #337ab7;
  color: #fff;
}

.user-info .greeting {
  float: right;
  width: 65%;
}

.navbar-toggle {
  float: left;
  margin-left: 10px;
  margin-right: -5px;
}

.container {
  width: 100% !important;
  margin: 0;
  max-width: 100%;
  padding-bottom: 70px;
}

.clearfix-after::after {
  display: block;
  content: '';
  clear: both;
}

.inherited-checkbox {
  opacity: 0.2;
}

.clearfix {
  display: block;
  content: '';
  clear: both;
}

a.btn-default {
  color: #333 !important;
}

.react-tel-input {
  text-align: initial;
}

.react-tel-input input[type='tel'],
.react-tel-input input[type='text'] {
  width: 100% !important;
}

.card-terms {
  height: 300px;
  overflow-y: scroll;
}

.card-title {
  font-size: 17px;
}

button {
  white-space: normal !important;
}

.button-list {
  button:not(:last-child),
  .button-link {
    margin-right: 1rem;
  }

  .separator {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    margin-right: 1rem;
  }

  .text {
    padding: 0.5rem 1rem 0;
  }

  .text:first-of-type {
    padding: 0.5rem 1rem 0 5px;
  }
}

.card-header .button-list {
  display: inline-flex;

  .dropdown button {
    margin-right: 0;
  }
}

.order-line:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  margin-top: 10px;
}

.order-item-price,
.order-subitem-price,
.order-summary-price,
.order-discount-price {
  color: #6c757d;
  font-size: 11px;

  .order-remove-item.material-icons {
    font-size: 20px;
    margin-top: -2.5px;
    margin-left: 3px;
    float: right;
    cursor: pointer;
  }

  .order-remove-item:hover {
    color: #dc3545 !important;
  }

  .order-item-delete {
    cursor: pointer;
    margin-top: 6px;
    color: red;
    text-align: center;
  }
}

.order .card-body {
  padding-bottom: 10px;
}

.order-item-option-body,
.order-item-addons {
  margin-top: 5px;
  font-size: 85%;
  margin-left: 20px;
}

.order-item-element .alert {
  margin-bottom: 0;
  border-radius: 0 0 0.25rem 0.25rem;
  border: 0;
}

.order-item,
.order-summary-item,
.order-discount-item,
.order-total {
  display: flex;
}

.order-item-description,
.order-summary-description,
.order-discount-description,
.order-total-description {
  flex: 1;
}

.order-item-price {
  text-align: right;
  width: 80px;
}

.order-item-price,
.order-subitem-price {
  margin-top: 2.5px;
}

.order-subitems {
  overflow-y: hidden;
  padding-top: 1px;
}

.order-subitem::before {
  content: '';
  position: absolute;
  left: -1rem;
  top: calc(0.65rem);
  height: 3px;
  width: 0.75rem;
  background-color: #8897a2;
}

.order-subitem::after {
  content: '';
  position: absolute;
  left: -1rem;
  bottom: calc(100% - 0.65rem);
  top: -12rem;
  width: 3px;
  background-color: #8897a2;
}

.order-subitem,
.order-subitem-configuration {
  position: relative;
  margin: 3px 0;
  margin-left: 25px;
}

.order-subitem-configuration {
  color: #6c757d;
}

.order-summary-item,
.order-discount-item,
.order-subitem-configuration {
  font-size: 12px;
}

.order-summary-item,
.order-discount-item {
  margin-bottom: 5px;
}

.order-total {
  margin-top: 8px;
  font-weight: bold;
}

.order-total-price {
  float: right;
}

.alert > p:last-of-type {
  margin-bottom: 0;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 10px 0;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.StripeElement:not(.payment-request) {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.indent {
  margin-left: 20px;
}

.childRegistration {
  padding: 0;
}

.childRegistration .material-icons {
  margin-top: 5px;
}

.card-header:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header.bg-danger {
  color: white;
}

.card-header .material-icons.icon {
  float: left;
  font-size: 42px;
  padding: 0 10px 10px 0;
}

.carousel-inner {
  text-align: center;
  background-color: #dee2e6;
  border-radius: 5px;
}

.custom-control-label {
  min-width: 21px;
  min-height: 21px;
}

.custom-control-label::before,
.custom-control-label::after {
  top: -0.05rem;
  left: -2rem;
  width: 1.5rem;
  height: 1.5rem;
}

.custom-switch {
  .custom-control-label::before {
    top: 0.2rem;
    height: 1rem;
  }

  .custom-control-label::after {
    top: calc(0.2rem + 2px);
  }
}

.custom-checkbox {
  margin-left: 7px;
}

.btn-link.compact {
  padding: 0;
}

.btn-link.plain {
  text-decoration: unset;
}

.productCategories {
  .nav-item {
    width: 100%;
    cursor: pointer;
  }

  .nav-item.active {
    background-color: #337ab7;

    a {
      color: #fff !important;
    }
  }

  .nav-item:not(.active) {
    a {
      color: #007bff;
    }
  }
}

.loading:not(.wizard-step) {
  text-align: center;
  color: #ccc;
  z-index: 50;
}

.inline-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.55);
}

.loading > p:first-of-type {
  margin-top: -2.5em;
}

.loader:not(.hidden),
.loader:not(.hidden):after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
}

.loader:not(.hidden) {
  border-top: 0.5em solid rgba(255, 255, 255, 0.2);
  border-right: 0.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.custom-checkbox.negative {
  .custom-control-input:checked {
    ~ .custom-control-label::after {
      content: '\00d7';
      color: white;
      font-size: 21px;
      margin-left: 0.3px;
      margin-top: -5.1px;
      background-image: none;
    }

    ~ .custom-control-label::before {
      background-color: #ff0023;
    }
  }

  .custom-control-input:checked:disabled {
    ~ .custom-control-label::before {
      background-color: rgba(255, 0, 35, 0.5);
    }
  }
}

.department-select {
  .custom-control {
    margin-right: -25px;
    padding-left: 2.25rem;
  }

  .department-select-item {
    padding: 8px 15px 0px;
  }

  .department-select-item:nth-of-type(even) {
    background-color: rgb(241, 241, 241);
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.theme-switcher {
  padding: 10px;
  display: inline-block;

  div {
    width: 25px;
    height: 25px;
    border: 1px solid;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
  }

  .light {
    background-color: #fff;
  }

  .dark {
    background-color: $bgDark;
  }
}

.btn:disabled {
  cursor: not-allowed;
}

.cookie-warning {
  background-color: #105b67 !important;
  position: absolute !important;
  width: 100%;
  z-index: 9999 !important;
  color: #fff !important;

  div {
    width: 100%;
    margin-top: -5px;
  }

  .material-icons {
    float: left;
    margin-right: 10px;
  }
}

.dropdown-menu {
  min-width: 15rem !important;
}

.dropdown-item {
  padding: 0.25rem 1.75rem !important;
}

.text-danger.dropdown-item:active {
  color: white !important;
}

.Toastify {
  position: absolute;
}

.Toastify__toast-body {
  margin-right: 5px;
}

.Toastify__toast-container {
  top: 0;
  right: 0;
  width: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  pointer-events: none;
  max-width: 50%;
  word-wrap: break-word;
}

.Toastify__toast {
  width: auto;
  float: right;
  pointer-events: auto;
}

.Toastify__toast-body a {
  cursor: alias;
  color: white;
  text-decoration: underline;
}

.chip {
  background-color: #b5b5b5;
  color: #353535;
  padding: 4px 7px;
  border-radius: 15px;
  margin: 8px;
  margin-left: 0;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.chip-dismiss {
  display: flex;
  padding: 2px;
  background-color: #5d5d5d;
  margin-left: 6px;
  border-radius: 12px;
  color: #cccccc;
}

.chip-dismiss,
.chip-dismiss .material-icons {
  cursor: pointer !important;
}

.autocomplete {
  background-color: white;
  width: auto;
  margin-left: 16px;
  left: 0;
  position: absolute;
  right: 0;
  overflow: auto;
  margin-right: 16px;
  border: 1px solid gray;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: black;
  z-index: 10;
  border-radius: 0 0 7px 7px;
}

.autocomplete-option {
  padding: 5px;
  cursor: pointer;
}

.autocomplete-option:hover {
  background-color: lightgray;
  overflow: hidden;
  transition: all 0.3s;
}

.search-item a {
  text-decoration: none;
}

.avatar-small {
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}

.avatar-medium {
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}

.badge span[name='close'].material-icons {
  float: right;
  margin-top: -5px;
  padding-top: 2px;
  font-size: 14px;
  padding-right: 0px;
  margin-right: -3px;
  padding-left: 3px;
}

.nav-tabs > .nav-item:not(:first-of-type) {
  margin-left: 0.5rem;
}

.tab-pane > .card {
  margin-top: -1px;
  border-radius: 0 0 0.25rem 0.25rem;
}

.table {
  table-layout: fixed;

  hr {
    margin: 0;
  }

  .table-item {
    transition: all 0.3s;
    min-height: 50px;
    padding: 10px 10px 12px;

    img {
      float: left;
      width: 80px;
      height: 80px;
    }

    p {
      margin: 0;
    }

    .material-icons {
      font-size: inherit;
    }

    .material-icons.icon {
      float: left;
      padding: 0 10px 10px 0;
      font-size: 42px;
    }
  }

  .table-item.selected {
    background-color: #337ab7;
    color: #fff;
  }

  .table-item.selected:first-child {
    border-radius: 3px 3px 0 0;
  }

  .table-item.selected:last-child {
    border-radius: 0 0 3px 3px;
  }
}

.table:not(.no-hover) {
  .table-item {
    cursor: pointer;
  }

  .table-item:not(.selected):hover {
    background-color: #e6eff7;
  }
}

.resultInfo {
  .card {
    position: sticky;
    top: 10px;
  }

  .card-body {
    max-height: 65vh;
    overflow-y: auto;

    [id^='department'] > .btn {
      font-size: 13px;
    }
  }

  .card-footer {
    text-align: center;
  }
}

.filter-options {
  .card-header {
    font-size: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;

    div {
      display: flex;
      margin-left: auto;
    }
  }

  .card-body {
    padding-bottom: 0 !important;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.wizard {
  display: flex;
  margin-bottom: 10px;
  color: #42526e;
  align-items: center;
}

.wizard > .wizard-step {
  flex-grow: 0.1;
}

.wizard > .wizard-divider {
  flex-grow: 1;
  height: 1px;
  background-color: #42526e;
}

.wizard-step .wizard-step-number {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border: 1px solid #42526e;
  align-items: center;
  display: flex;
}

.wizard-step .wizard-step-description {
  padding-top: 5px;
  font-size: 12px;
}

.wizard-step.complete .wizard-step-number {
  border-color: #29b671;
  background-color: #29b671;
  color: #ebecf0;

  .material-icons {
    margin-top: 6px;
    margin-left: -1px;
    font-size: 20px;
  }
}

.wizard-step.error .wizard-step-number {
  border-color: #a33c3c;
  background-color: #a33c3c;
  color: #ebecf0;

  .material-icons {
    margin-top: 5px;
    margin-left: -1px;
    font-size: 20px;
  }
}

.wizard-step.active .wizard-step-number {
  color: #ebecf0;
  border-color: #0670ad;
  background-color: #0670ad;
}

.wizard-step.loading {
  .wizard-step-number {
    border: 0;
  }

  .wizard-step-number span {
    margin-left: 11px;
  }

  .wizard-step-number:after {
    content: '';
    position: relative;
    width: 30px;
    height: 30px;
    margin-left: -18px;
    border-radius: 15px;
    border-top: 1.5px solid #e5ad69;
    animation: spinner 0.7s linear infinite;
  }
}

.wizard-step > div > span {
  flex-grow: 1;
}

.dark-mode {
  .wizard {
    color: #ebecf0;
  }
}

.inline-icon {
  margin-left: 5px;

  .material-icons {
    position: absolute;
  }
}

ul.flushLeft {
  list-style-position: inside;
  padding-left: 0;
}

.badge-list {
  span.pretext {
    margin-right: 8px;
  }

  .badge {
    cursor: pointer;
    font-size: 11px;
    font-weight: normal;
    padding: 10px;
    margin-top: -3px;
    margin-bottom: 5px;
  }

  .badge:not(:last-of-type) {
    margin-right: 5px;
  }
}

.badge-list.compact {
  .badge {
    padding: 0.42em 0.7em;
  }
}

.badge-list.small {
  .badge {
    padding: 0.25em 0.4em;
  }
}

.badge.with-inline-icon {
  padding-right: 1.8em !important;

  .inline-icon {
    margin: 0;
  }

  .inline-icon .material-icons {
    margin-top: -4px;
  }
}

.badgeLabel {
  *,
  ::after,
  ::before {
    box-sizing: content-box;
  }
}

a.button-link {
  text-decoration: none !important;
}

:not(a) > .material-icons {
  cursor: default;
}

.material-icon-text {
  display: flex;
  flex-direction: row;

  div {
    flex: 1;
    padding-top: 2px;
    padding-left: 0.65em;
  }

  span {
    align-self: center;
  }
}

.react-tel-input {
  .country-list {
    z-index: 10000;
  }
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  background-color: #fff;
  color: #444;
}

.productItem {
  padding: 10px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  flex-direction: row;

  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);

  p {
    margin-bottom: 0;
  }

  .productItemImage {
    flex: 1;
    margin-right: 10px;
    border-radius: 5px;
    text-align: center;

    img {
      height: 80px;
      width: 80px;
    }
  }

  .productInfo {
    flex: 4;
  }

  .productPurchaseBar, .productChangeBar {
    display: flex;
    position: absolute;
    bottom: -25px;
    right: 15px;
    width: 250px;
    background-color: #6c757d;
    color: #fff;
    align-items: center;
    padding: 5px;
    border-radius: 5px;

    .productPurchaseBarPrice {
      margin: 10px;
      font-size: 15px;
      text-align: center;
    }

    .productPurchaseBarPrice.productWithDiscount {
      margin: 0 10px 0;
    }

    .btn {
      margin: 0 5px;
      flex: 2;
    }
  }
}

.productItem.noPurchaseBar {
  padding-bottom: 5px;
}

.pagination {
  justify-content: center;
  margin-top: 1rem;

  .page-link:disabled {
    color: grey;
  }
}

.data-item {
  padding: 8px 17px;
  min-height: 64px;
  border-top: 1px solid #dee2e6;

  .data-item-right {
    float: right;
    display: inline-flex;
  }

  .data-item-right-text {
    margin-right: 17px;
    margin-top: 7px;
  }
}

.dark-mode {
  background-color: $bgDark;
  color: #ccc;

  .department-select {
    .department-select-item:nth-of-type(even) {
      background-color: $bgDark;
    }
  }

  a:not(.btn) {
    color: #4bc3ff !important;
  }

  kbd {
    background-color: $highlightDark;
    color: #ddd;
  }

  .table {
    color: #ccc;
  }

  .table-item:not(.selected):hover {
    background-color: #555;
  }

  .sidenav {
    background-color: $bgCardDark;
    border-color: $bgCardDark $highlightDark $highlightDark $bgCardDark;
  }

  .sidenav li a:not(.active):hover div {
    background-color: #555;
  }

  .event-info,
  .user-info,
  .sidenav li {
    border-bottom-color: $bgDark;
  }

  .footer:not(.cookie-warning) {
    background-color: $bgCardDark !important;
    border-top: 1px solid $highlightDark;
  }

  .card {
    background-color: $bgCardDark;
    border-color: $highlightDark;
  }

  .card.danger {
    background-color: #5d3f43;
  }

  .card.success {
    background-color: #43634a;
  }

  .card.warning {
    background-color: #79653f;
  }

  .modal-content {
    background-color: $bgCardDark;
    border-color: $highlightDark;
    color: #ccc;

    .close {
      color: #bbb;
    }
  }

  hr,
  .modal-header,
  .modal-footer {
    border-color: #666;
  }

  .strike > span:before,
  .strike > span:after {
    background-color: #666;
  }

  .order-subitem-configuration,
  .order-item-price,
  .text-muted,
  .dial-code {
    color: #888;
  }

  .avatar > img,
  [class^='avatar-'] {
    border-color: $bgDark;
  }

  .react-tel-input {
    .country-list {
      background-color: #555 !important;

      .country:hover {
        background-color: #777;

        .dial-code {
          color: #aaa;
        }
      }

      .country.highlight {
        background-color: #999 !important;

        .dial-code {
          color: #bbb !important;
        }
      }
    }
  }

  .nav-link.active {
    background-color: #333;
  }
}

#printArea {
  visibility: hidden;
}

@media (max-width: 575px) {
  .Toastify__toast-container {
    max-width: 100%;
    width: 100%;
  }

  .Toastify__toast {
    width: 100%;
  }

  .Toastify__toast-body {
    max-width: 95%;
  }

  .container {
    padding-bottom: 85px;
  }

  .sidenav-active {
    .sidenav {
      position: relative;
    }

    .footer,
    .page-wrap {
      display: none;
    }
  }

  .page-wrap {
    padding-top: 0;
    margin-left: 0;
  }

  .header-spacer {
    margin-bottom: 1em;
  }

  :not(.hasSelectedRow) > .resultInfo {
    display: none;
  }

  .hasSelectedRow .resultTable {
    display: none;
  }

  .resultInfo {
    .card-body {
      max-height: 100% !important;
    }
  }
}

@media (min-width: 576px) {
  .show-mobile {
    display: none;
  }

  .sidenav .navbar-toggler {
    display: none;
  }

  .sidenav-active {
    .sidenav {
      width: 300px;
    }
  }

  .toggle-btn,
  .header {
    display: none;
  }

  .modal-large.modal-dialog {
    max-width: 750px !important;
  }
}

@media print {
  .badgeLabel {
    break-after: page;
  }

  #app, .hide-print {
    display: none !important;
  }

  @page {
    size: auto;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    min-width: unset !important;
  }

  .container {
    min-width: unset !important;
  }

  #app {
    display: none;
  }

  #printArea {
    color: #000;
    visibility: visible;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.inline-help {
  font-size: 80%;
}

.badge-pill {
  padding: 5px 10px !important;
}

.card-sub-header {
  margin-top: 7px;
  float: left;
}

.comma-list {
  display: inline;
  list-style: none;
  padding: 0px;

  li {
    display: inline;
  }

  li::after {
    content: ', ';
  }

  li:last-child::after {
    content: '';
  }
}

.markdown-container img {
  object-fit: contain;
  max-width: 100%;
}

.hover-edit .hover-edit-icon {
  display: none;
}

.hover-edit:hover .hover-edit-icon {
  display: inline;
  overflow: hidden;
  line-height: 1;
  min-width: 30px;
}

.overflow-ellipis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overlay {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  color: white;
  text-shadow: 0 0 3px black, 0 0 5px black;
}

.overlay:hover {
  opacity: 1;
}

.avatar:hover img {
  background-color: black;
  opacity: 0.5;
}


.profilePictureDeleteButton {
  opacity: 0.5;
  font-weight: bold;
  position: absolute;
  left: calc(50% + 32px);
  bottom: 0px;
  color: red;
  cursor: pointer;
}

.profilePictureDeleteButton:hover {
  opacity: 1.0;
  cursor: pointer;
}
