.support-glass-break-banner {
  padding: 0 1.2em;
  margin-bottom: 1em;

  .material-icons {
    padding-left: 0.5em;
    margin-right: 0.5em;
  }

  .row {
    padding: 0 1em;
  }

  .banner-message {
    padding: 0.25em;
    display: flex;
    border: 1px solid #f44336;
    border-radius: 5px;
  }
}
