.reg-transfer-error {
  display: flex;
  margin-top: 1em;

  div .material-icons {
    margin-right: 10px;
  }

  p {
    margin-bottom: 0;
  }
}