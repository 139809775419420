@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.cashier-settings-login {
  display: flex;
  align-items: center;
  margin: 3em;

  .cashier-settings-login-pip {
    flex: 1;
    display: inline-flex;
    justify-content: center;

    &:after {
      width: 2em;
      height: 2em;
      background-color: #dbdbdb;
      border-radius: 50%;
      border: 1px solid #aaa;
      content: ' ';
      display: flex;
    }

    &.cashier-settings-login-pip-ok:after {
      border-color: #666;
      background-color: rgb(20, 206, 20);
    }
  }
}

.cashier-settings-form-item {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;

  .cashier-form-item-input {
    flex: 1;
  }

  .cashier-form-item-description {
    flex: 1.5;
    font-size: 0.9em;
    color: #666;
  }
}

.cashier-form-item-saved {
  margin-top: 0.3em;
  font-size: 0.95em;
  font-weight: 600; 
  color: rgb(25, 193, 25);
}

.cashier-explanation-text {
  flex: 1.5;
  font-size: 0.9em;
  color: #666;
}

.cashier-settings-modal .modal-body {
  max-height: 500px;
  overflow: scroll;
}

.cashier-badgetype-header {
  display: flex;
  align-items: center;

  .cashier-form-item-saved {
    margin-top: 0;
    margin-left: 1em;
  }
}