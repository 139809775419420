.social-link {
  font-size: 12px;
}

.social-link-logo img {
  max-width: 64px;
  max-height: 64px;
}

.social-link-name {
  font-size: 16px;
  font-weight: bold;
}

.social-link button {
  margin-top: 2em;
}
