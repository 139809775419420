.form-alert {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 1em;

  > * > p:last-child {
    margin-bottom: 0;
  }
}

.form-alert-warning {
  border-color: #ffaf05;
  background-color: #fff5cd;
}

.form-alert-error {
  border-color: #db2c29;
  background-color: #fde2d4;
}

.form-alert-info {
  border-color: #006ce0;
  background-color: #caedfd;
}

.form-alert-success {
  border-color: #2eb21c;
  background-color: #e3fbd0;
}
