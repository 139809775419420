.loading {
  > div {
    top: 2rem;
    position: relative;
  }

  .spinner-border {
    margin-bottom: 0.5em;
  }
}

.loading:not(.inline-loading) {
  > div {
    margin-top: -30px;
    margin-bottom: 30px;
  }
}
